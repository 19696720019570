import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"

export default function PrivacyPolicy() {
  return (
    <Layout>
      <section className="content">
        <h1 className="content__heading content__heading--large policy-text--center">
          GS VANLIFE, INC.
          <br />
          WEBSITE PRIVACY POLICY
        </h1>
        <p className="content__body">
          GS VanLife, Inc. (“GS,” “we,” “us,” or “our”) is committed to
          addressing privacy issues and wants you to be familiar with how we may
          collect, use and disclose your Personal Information. This Website
          Privacy Policy (this “Policy”) describes our practices in connection
          with Personal Information that we collect through our websites from
          which you are accessing this Policy (the “Website”) as well as through
          our app and email messages that we may send to you that link to this
          Policy. “You” or “your” refers to a visitor to or user of our Website
          as well as job applicants and others who contact us about employment
          opportunities at GS. By providing Personal Information to us, you
          acknowledge that you understand and agree to this Policy.
        </p>
        <p className="content__body">
          This Policy applies to Personal Information collected by or through
          our Website or other similar channels, as well as Personal Information
          collected through our reservation and related services (the
          “Services”) that are available on our Website or app.
        </p>
        <p className="content__body">
          Our Website is not directed to minors or anyone who is not eligible to
          use the Services for any reason. We do not promote or market our
          Products to minors. Please see Section 10 below and our{" "}
          <Link to="/terms-of-use/">Terms of Use</Link> for additional
          information regarding use of our Website by minors.
        </p>
        <p className="content__body">
          If you are located in the European Union, or are a California
          resident, please see additional information below. GS VanLife, Inc. is
          responsible for the processing of your Personal Information under this
          Policy.
        </p>
        <p className="content__body">
          Please direct any questions regarding the processing of your Personal
          Information as indicated below under the “Contacting Us” section
          below.
        </p>
        <ol className="content__body">
          <li>
            <h2 className="content__heading content__heading--medium">
              PERSONAL INFORMATION WE MAY COLLECT
            </h2>
            <p className="content__body content__body--no-indent">
              “Personal Information” means any information relating to an
              identified or identifiable individual. We may collect the
              following categories and specific elements of information that,
              alone or in combination, may be Personal Information depending on
              the applicable laws in your location:
            </p>
            <ul>
              <li>
                Contact Information, such as: name, postal address, telephone
                number and email address.
              </li>
              <li>
                Electronic Information, such as: IP address, cookie ID, hardware
                identifier, mobile identifiers, browser and device information
                for the device you used to access the Website and demographic
                information.
              </li>
              <li>
                Social Media Information, such as: social media URL, social
                media alias, social media profile data and other information
                depending on the social media platform.
              </li>
            </ul>
            <p className="content__body content__body--no-indent">
              If you choose to apply for job openings or otherwise contact us
              regarding employment opportunities, we may collect the following:
            </p>
            <ul>
              <li>
                Contact Information, such as: name, mailing address, phone
                number and email address.
              </li>
              <li>
                Employment Information, such as: prior employers and dates of
                employment.
              </li>
              <li>
                Education Information, such as: education history, degrees
                earned and institutions attended.
              </li>
              <li>
                Job Qualification Information, such as: resume, CV, educational
                transcripts and any information contained in or with such
                documentation.
              </li>
              <li>
                Work Eligibility Information, such as: country of residence,
                visa and immigration status and citizenship.
              </li>
              <li>
                Reference Information, such as: name and contact details of your
                references and information your references provide.
              </li>
              <li>
                Compensation Information You Voluntarily Provide, such as: past
                compensation details and desired compensation details. Note that
                we do not request past compensation details and any and all such
                information is provided voluntarily at your election.
              </li>
              <li>
                Social Media Information You Voluntarily Provide, such as:
                social media URL, social media alias, social media profile data
                and other information depending on the social media platform.
              </li>
              <li>Background check information, subject to applicable law.</li>
              <li>
                Sensitive Personal Information, such as: race, disability status
                and nationality that you provide voluntarily or which we collect
                on a non-mandatory basis in order to comply with applicable law.
              </li>
            </ul>
            <p className="content__body">
              In some instances, we may combine information above that is not
              identifiable with Personal Information. If we so combine
              information such that it is Personal Information, the combined
              Personal Information will be treated by us as Personal Information
              as long as it is so combined.
            </p>
            <p className="content__body">
              We may use a third-party payment service to process payments made
              through the Website and our app. If you wish to make a payment
              through the Website or app, your Personal Information, including
              payment details, may additionally be collected by the third party,
              and not by us, and will be subject to such third party’s privacy
              policy and other terms, rather than this Policy or our Terms of
              Service. We have no control over, and are not responsible for,
              such third party’s collection, use, and disclosure of your
              Personal Information.
            </p>
            <p className="content__body">
              If you submit any Personal Information relating to other people to
              us or to our service providers, such as contact information of
              references provided in connection with a job application, you are
              responsible for providing any notices and obtaining any consents
              that may be necessary for us or our service providers to process
              such information, and you represent that you have the authority to
              do so and to permit us and our service providers to use such
              information in accordance with this Policy.
            </p>
          </li>
          <li>
            <h2 className="content__heading content__heading--medium">
              SOURCES OF PERSONAL INFORMATION
            </h2>
            <p className="content__body">
              We may collect or source Personal Information from the following
              categories of sources:
            </p>
            <p className="content__body content__body--no-indent">
              <span className="content__body--underline">
                FROM YOU THROUGH THE WEBSITE OR THE APP:
              </span>{" "}
              We may collect Personal Information directly or indirectly from
              you through the Website or the app, e.g., when you sign up for a
              newsletter, create an account, otherwise use our Website or app,
              contact us through our communication channels, communicate with us
              through social media platforms, or interact with us at in-person
              at events or over the phone. Some information may be collected
              using cookies or other similar technologies. Please review the
              section below “Cookies And Other Technologies” for further
              information.
            </p>
            <p className="content__body content__body--no-indent">
              <span className="content__body--underline">
                FROM OTHER SOURCES:
              </span>{" "}
              We may receive your Personal Information from other sources, such
              as public databases; joint marketing partners; marketing service
              and data providers; social media platforms; and from people with
              whom you are friends or otherwise connected on social media
              platforms, as well as from other third parties.
            </p>
          </li>
          <li>
            <h2 className="content__heading content__heading--medium">
              HOW WE MAY USE PERSONAL INFORMATION
            </h2>
            <p className="content__body">
              We may use Personal Information for the following business and
              other purposes:
            </p>
            <ul>
              <li>To respond to your inquiries.</li>
              <li>
                To send administrative information to you, for example,
                information regarding the Website and Services we offer, and
                changes to our terms, conditions and policies.
              </li>
              <li>
                To complete and fulfill your orders, for example, to process
                your payments, deliver your Services to you, communicate with
                you regarding your reservations and provide you with related
                customer service and support.
              </li>
              <li>
                To send you marketing communications that we believe may be of
                interest to you and otherwise for our marketing, advertising and
                promotion activities related to our Website.
              </li>
              <li>To operate, maintain, secure and protect our Website.</li>
              <li>
                For our business purposes, such as data analysis, audits, fraud
                monitoring and prevention, developing new products, enhancing,
                improving or modifying our Website and Services, identifying
                usage trends, determining the effectiveness of our promotional
                campaigns and operating and expanding our business activities.
              </li>
              <li>
                To process your job application(s) or otherwise respond to you
                regarding employment inquiries.
              </li>
              <li>
                To engage in other legitimate purposes as required or permitted
                by applicable law.
              </li>
              <li>To comply with applicable laws and regulations.</li>
            </ul>
          </li>
          <li>
            <h2 className="content__heading content__heading--medium">
              HOW WE MAY SHARE INFORMATION
            </h2>
            <p className="content__body">
              Your Personal Information may be disclosed to the following
              categories of third parties:
            </p>
            <ul>
              <li>
                To any affiliates and subsidiaries for the purposes described in
                this Policy.
              </li>
              <li>
                To our third-party service providers who provide services such
                as website hosting, data analysis, payment processing, order
                fulfillment, information technology and related infrastructure
                services, customer service, email delivery and other advertising
                and marketing services, auditing and other similar services.
              </li>
              <li>
                To identify you to anyone to whom you send messages through the
                Website.
              </li>
              <li>
                To otherwise share your Personal Information with your consent
                or pursuant to your instructions.
              </li>
              <li>
                To a third party in the event of any reorganization, merger,
                sale, joint venture, assignment, transfer or other disposition
                of all or any portion of our business, assets or stock.
              </li>
              <li>
                To a competent authority in response to a request for
                information if we believe disclosure is in accordance with, or
                is otherwise required by, any applicable law, regulation or
                legal process, including to meet national security or law
                enforcement requirements.
              </li>
              <li>
                To law enforcement officials, government authorities or other
                third parties as necessary to protect our or your rights,
                property or safety, or that of our business partners, or as
                otherwise required by applicable law, for example to investigate
                fraud.
              </li>
              <li>In an aggregated and de-identified or anonymized form.</li>
              <li>
                For other legitimate purposes permitted by applicable law.
              </li>
            </ul>
          </li>
          <li>
            <h2 className="content__heading content__heading--medium">
              COOKIES AND OTHER TECHNOLOGIES
            </h2>
            <p className="content__body">
              Cookies are randomly-generated alphanumeric pieces of information
              that a website transfers to your computer used to browse a
              website. Cookies allow us to collect information such as browser
              type, time spent on the Website, pages visited, language
              preferences and other internet traffic data. Similar technologies
              may be used instead of or in conjunction with cookies, such as web
              beacons, which are small, non-recognizable pictures downloaded to
              your computer when a specific website is loaded.
            </p>
            <p className="content__body">
              We use information from cookies and similar technologies for the
              following purposes:
            </p>
            <ul>
              <li>To help maintain the security of our Website.</li>
              <li>
                To facilitate navigation, display information more effectively
                and to personalize your experience while using the Website,
                including recognizing you as a unique visitor (with a random
                alphanumeric identifier).
              </li>
              <li>
                To conduct Website analytics to understand how users use our
                Website and improve the Website.
              </li>
              <li>To assist your use of the Website.</li>
              <li>
                To help us resolve questions and other inquiries regarding the
                Website.
              </li>
              <li>
                To allow us to tailor content or advertisements to match your
                preferred interests.
              </li>
              <li>To avoid showing you the same advertisements repeatedly.</li>
              <li>
                To support sales and marketing activities such as campaign
                analytics and provide direct marketing communications to you if
                you have consented to receive such communications, where
                applicable.
              </li>
            </ul>
            <p className="content__body content__body--no-indent">
              <span className="content__body--underline">TYPES OF COOKIES</span>
            </p>
            <p className="content__body content__body--no-indent">
              <span className="content__body--underline">
                Required Cookies:
              </span>{" "}
              Required cookies are necessary for the operation, maintenance
              and/or security of our Website.
            </p>
            <p className="content__body content__body--no-indent">
              <span className="content__body--underline">
                Functionality Cookies:
              </span>{" "}
              These cookies collect information about how visitors use our
              Website, such as which pages visitors go to most often and if they
              receive error messages from web pages. As set forth above, we may
              use third parties to help us understand how visitors engage with
              our Website. This means that when you engage with our Website,
              your browser automatically sends certain information to these
              third parties. This includes, for example, the web address of the
              page you’re visiting and your IP address. These cookies
              additionally remember choices you make (such as your language or
              the region you are in) and provide enhanced or more personal
              features. These cookies can also be used to remember changes you
              have made to text size, fonts and other parts of web pages you can
              customize.
            </p>
            <p className="content__body content__body--no-indent">
              <span className="content__body--underline">
                Targeting and Advertising Cookies:
              </span>{" "}
              These cookies are used to deliver advertising more relevant to you
              and your interests. They are also used to limit the number of
              times you see a certain advertisement as well as help measure the
              effectiveness of the advertising campaigns. They may be placed by
              third-party advertising networks with our permission. They
              remember that you have visited a website and may share this
              information with other organizations such as advertisers
            </p>
            <p className="content__body">
              Two types of cookies may be used on our Websites: session cookies
              (which are temporary cookies that remain in the cookie file of
              your browser until you leave the site) and persistent cookies
              (which remain in the cookie file of your browser for longer,
              though the time period will depend on the lifetime of the specific
              cookie).
            </p>
            <p className="content__body content__body--no-indent">
              <span className="content__body--underline">
                DISABLING AND ENABLING COOKIES
              </span>
            </p>
            <p className="content__body">
              You can use your browser or device settings to review and erase or
              delete cookies at any time. Note that by disabling certain
              categories of cookies you may be prevented from accessing some
              features of our Website, certain content or functionality may not
              be available, or the Website may not operate correctly.
            </p>
            <p className="content__body">
              Depending on your location, you may also opt out of third-party
              cookies relating to behavioral advertising by visiting the
              following websites:
            </p>
            <ul>
              <li>
                Network Advertising Initiative (NAI):{" "}
                <a href="http://optout.networkadvertising.org">
                  http://optout.networkadvertising.org
                </a>
              </li>
              <li>
                Digital Advertising Alliance (DAA):{" "}
                <a href="http://www.aboutads.info/choices/">
                  http://www.aboutads.info/choices/
                </a>
              </li>
              <li>
                European Interactive Digital Adverting Alliance (EDAA):{" "}
                <a href="https://www.youronlinechoices.eu">
                  www.youronlinechoices.eu
                </a>{" "}
                and <a href="http://www.edaa.eu/">http://www.edaa.eu/</a>
              </li>
            </ul>
            <p className="content__body">
              Opting out does not mean you will no longer receive online
              advertising. It does mean that the company or companies from which
              you opted out will no longer deliver ads tailored to your browsing
              preferences and usage patterns. You may temporarily continue to
              see some tailored ads produced from ongoing advertising campaigns.
            </p>
            <p className="content__body">
              The above opt-out tools rely on the use of cookies, and to the
              extent your browser settings impact use of third-party cookies,
              the above tools may not operate correctly. The above tools also
              apply only to the browser on which they are exercised. If you want
              to apply these tools to multiple devices or multiple browsers, you
              must exercise these tools on each browser and/or device.
            </p>
            <p className="content__body content__body--no-indent">
              <span className="content__body--underline">DO NOT TRACK</span>
            </p>
            <p className="content__body">
              There are different ways to prevent tracking of online activities.
              Some web browsers may allow you to enable a do-not-track feature
              that alerts the websites you visit that you do not want your
              online activities to be tracked. Our Website may not recognize or
              react in response to do-not-track signals. At present, no
              generally accepted standards exist on how companies must respond
              to do-not-track signals. In the event a final standard is
              established, we will assess and provide an appropriate response to
              these signals.
            </p>
            <p className="content__body content__body--no-indent">
              <span className="content__body--underline">
                GOOGLE ANALYTICS, AWS AND OTHER SERVICES
              </span>
            </p>
            <p className="content__body">
              We may use Google Analytics, AWS and similar services to
              understand how users use our Website. To learn more about Google
              Analytics and its practices, please visit the{" "}
              <a href="http://www.google.com/policies/privacy/partners/">
                Google Analytics web page
              </a>{" "}
              or the Google Analytics{" "}
              <a href="https://support.google.com/analytics/answer/6004245?hl=en">
                “Safeguarding your data” web page
              </a>
              . To learn about adjusting your Google Analytics cookie settings,
              please visit the{" "}
              <a href="http://tools.google.com/dlpage/gaoptout">
                Google Analytics browser privacy settings website
              </a>
              .
            </p>
            <p className="content__body">
              The Website uses font files from Google Fonts and Adobe Fonts. To
              properly display the Website to you, servers where the font files
              are stored may receive personal information about you, including:
            </p>
            <ul>
              <li>Information about your browser, network or device</li>
              <li>Your IP address</li>
            </ul>
          </li>
          <li>
            <h2 className="content__heading content__heading--medium">
              THIRD PARTY SERVICES
            </h2>
            <p className="content__body">
              This Policy does not address, and we are not responsible for, the
              privacy, information, security, data protection or other practices
              of any third parties, including any third party operating any site
              or service that links to the Website. The inclusion of a link on
              the Website does not imply endorsement of the linked site or
              service by us or by our affiliates. We are not responsible for the
              collection, usage and disclosure policies and practices (including
              the data security practices) of other organizations, including
              social media platforms, such as Facebook, Instagram, Twitter,
              Apple, Google, Microsoft or any other application developer,
              application provider, social media platform provider, operating
              system provider, wireless service provider or device manufacturer,
              including any Personal Information you disclose to other
              organizations.
            </p>
          </li>
          <li>
            <h2 className="content__heading content__heading--medium">
              SECURITY
            </h2>
            <p className="content__body">
              We implement appropriate physical, technical and administrative
              measures designed to protect your Personal Information that we
              maintain within our organization against accidental or unlawful
              destruction or accidental loss, damage, alteration, unauthorized
              disclosure or access, as well as all other forms of unlawful
              processing. Unfortunately, no data transmission or storage system
              can be guaranteed to be 100% secure. If you have reason to believe
              that your interaction with us is no longer secure (for example, if
              you feel that the security of any account you might have with us
              has been compromised), please immediately notify us of the problem
              by contacting us in accordance with the “Contacting Us” section
              below.
            </p>
            <p className="content__body">
              If we learn of and confirm the occurrence of a security incident
              leading to the misappropriation or accidental or unlawful
              destruction, loss, alteration, unauthorized disclosure of, or
              access to, your personal information transmitted, stored or
              otherwise processed on our systems that compromises the
              confidentiality, integrity or availability of your Personal
              Information, we may attempt to notify you electronically by
              posting a notice on our Website or by sending you an email or
              otherwise in accordance with applicable law.
            </p>
          </li>
          <li>
            <h2 className="content__heading content__heading--medium">
              YOUR CHOICES
            </h2>
            <p className="content__body">
              In this section, we describe the rights and choices available to
              you.
            </p>
            <p className="content__body content__body--no-indent">
              Choosing not to provide your Personal Information. You may choose
              not to provide Personal Information. If you choose not to provide
              Personal Information (or ask us to delete it), we may not be able
              to provide you with certain Website functionality or certain
              Services. We will tell you what information you must provide to
              receive the Service by designating it as required at the time of
              collection or through other appropriate means.
            </p>
            <p className="content__body content__body--no-indent">
              Opt out of marketing communications. You may opt out of
              marketing-related emails by following the opt-out or unsubscribe
              instructions at the bottom of the email, or by contacting us at{" "}
              <a href="mailto:info@nicesquirrel.com">info@nicesquirrel.com</a>.
              If you opt out of marketing communications, you may continue to
              receive service-related and other non-marketing emails.
            </p>
            <p className="content__body content__body--no-indent">
              Cookies &amp; browser web storage and targeted online advertising.
              We may allow service providers and other third parties to use
              cookies and similar technologies to track your browsing activity
              over time and across the Website and third party websites. If
              business partners collect information about your activities on or
              through the Website, they may be members of organizations or
              programs that provide choices to individuals regarding the use of
              their browsing behavior or mobile application usage for purposes
              of targeted advertising. For more details, see Section 5 above.
            </p>
            <p className="content__body content__body--no-indent">
              Do not track. Some Internet browsers may be configured to send “Do
              Not Track” signals to the online services that you visit. We
              currently do not respond to “Do Not Track” or similar signals. To
              find out more about “Do Not Track,” please visit{" "}
              <a href="http://www.allaboutdnt.com">
                http://www.allaboutdnt.com
              </a>
              .
            </p>
          </li>
          <li>
            <h2 className="content__heading content__heading--medium">
              RETENTION PERIOD
            </h2>
            <p className="content__body">
              We will retain your Personal Information for the period necessary
              to fulfill the purposes outlined in this Policy unless a longer
              retention period is required or permitted by applicable law.
            </p>
            <p className="content__body content__body--no-indent">
              <span className="content__body--underline">
                USE OF WEBSITES AND PRODUCTS BY MINORS
              </span>
            </p>
            <p className="content__body">
              The Website is not directed to individuals under the age of
              eighteen (18), and we request that these individuals do not
              provide Personal Information through the Website. If you believe
              that we have mistakenly or unintentionally collected personal
              information of a minor through our Website without appropriate
              consent, please notify us as set forth below so that we may
              immediately delete the information from our servers and make any
              other necessary corrections. Please see our{" "}
              <Link to="/terms-of-use/">Terms of Use</Link> for more information
              on use of our Website by individuals under 18.
            </p>
          </li>
          <li>
            <h2 className="content__heading content__heading--medium">
              CROSS-BORDER TRANSFER
            </h2>
            <p className="content__body">
              The Website is controlled and operated by us from the United
              States. Your Personal Information may be stored and processed in
              any country where we have facilities or in which we engage service
              providers, and by using the Website you consent to the transfer of
              information to countries outside of your country of residence,
              including the United States, which may have different data privacy
              and data protection rules than those of your country. To the
              extent Personal Information is transferred to a recipient in a
              country that does not provide an adequate level of protection for
              Personal Information, we will take measures designed to adequately
              protect your Personal Information in accordance with applicable
              law.
            </p>
          </li>
          <li>
            <h2 className="content__heading content__heading--medium">
              SENSITIVE INFORMATION
            </h2>
            <p className="content__body">
              Do not send us, directly or indirectly, any sensitive or special
              categories of Personal Information (e.g., social security numbers
              or other national identifiers, information related to racial or
              ethnic origin, political opinions, religion or other beliefs,
              health, biometrics or genetic characteristics, criminal background
              or trade union membership) on or through the Website, the app or
              otherwise. We do not require and do not request any sensitive or
              special categories of Personal Information in connection with our
              provision of the Website or the app or the Services.
            </p>
          </li>
          <li>
            <h2 className="content__heading content__heading--medium">
              EU PRIVACY RIGHTS
            </h2>
            <p className="content__body">
              To the extent that European Union (“EU”) data protection laws
              apply to the processing of your Personal Information, you may have
              certain choices and means and other rights with respect to the
              processing of your Personal Information, as outlined below.
            </p>
            <p className="content__body">
              To exercise these rights and controls, please contact
              info@nicesquirrel.com.
            </p>
            <ul className="content__body--indent">
              <li>
                <span className="content__body--underline">Access:</span> You
                have the right to access a copy of the Personal Information that
                we hold about you in machine-readable form.
              </li>
              <li>
                <span className="content__body--underline">Correction:</span>{" "}
                You may correct some of your Personal Information if it is
                inaccurate or it needs to be updated.
              </li>
              <li>
                <span className="content__body--underline">Deletion:</span> You
                may secure the deletion of some of your Personal Information,
                subject to certain legal obligations or exceptions.
              </li>
              <li>
                <span className="content__body--underline">
                  Objections or Restrictions on use:
                </span>{" "}
                You may object to our processing of some of your Personal
                Information or restrict or limit our use of some Personal
                Information about you.
              </li>
              <li>
                <span className="content__body--underline">Portability:</span>{" "}
                If you wish to transfer your Personal Information to another
                data controller, you may ask us to do so, and we will send it
                directly to them to the extent feasible.
              </li>
              <li>
                <span className="content__body--underline">
                  Withdrawal of consent:
                </span>{" "}
                You may withdraw any consents you previously gave us.
              </li>
            </ul>
            <p className="content__body content__body--no-indent">
              Please note that some functionalities may not be available, or we
              may not be able to fulfill your request, depending on our legal
              obligations.
            </p>
            <p className="content__body content__body--no-indent">
              Certain other details regarding the processing of Personal
              Information that individuals located in the EU may be entitled to
              receive are contained in other provisions of this Policy.
            </p>
            <p className="content__body content__body--no-indent">
              As a security measure, we may need to request specific information
              from you to help us verify your identity and ensure your right to
              access your personal information (or to exercise any of your other
              rights).
            </p>
            <p className="content__body content__body--no-indent">
              Personal Information collected on or about individuals located in
              the EU is collected on the following legal bases for processing:
            </p>
            <ul className="content__body--indent">
              <li>
                Necessary for Performance of a Contract: If we or our affiliates
                have entered into a contract with customers, partners, suppliers
                or other third parties, we may need to process your Personal
                Information.
              </li>
              <li>
                Consent: Where you have provided your consent for us to engage
                in marketing activities with you, we may need to process your
                Personal Information.
              </li>
              <li>
                Legitimate Interest: We may need to process your Personal
                Information where we have a legitimate interest in doing so,
                such as: (1) to communicate with you in response to your
                requests, questions, inquiries, and submissions; (2) to conduct
                advertising, marketing and promotional activities in connection
                with operating our business; (3) for research and development,
                security and optimization of our Website, app and Services; and
                (4) to process a job application and communicate with you
                regarding your interest in employment with us.
              </li>
              <li>
                Comply with Legal Obligations: Where we are required to comply
                with laws or legal obligations.
              </li>
            </ul>
            <p className="content__body content__body--no-indent">
              Note that we may process your Personal Information using more than
              one legal basis depending on the specific purpose for which we are
              using your personal information.
            </p>
            <p className="content__body">
              Your Personal Information will be hosted in the United States and
              will therefore be transferred and stored outside of the European
              Economic Area. For the purpose of applicable EU laws, such third
              countries (including the United States) may not offer the same
              level of data protection as your country of residence. For further
              information about the safeguards used, please contact us as set
              forth below.
            </p>
            <p className="content__body">
              Should you wish to lodge a complaint with regards to how your
              Personal Information has been processed by us, you may contact
              your local supervisory or data protection authority.
            </p>
          </li>
          <li>
            <h2 className="content__heading content__heading--medium">
              CALIFORNIA PRIVACY RIGHTS
            </h2>
            <p className="content__body">
              If you are a California resident, you may have the right to
              disclosure of information about our collection, sale or disclosure
              for a business purpose of your Personal Information, including, to
              the extent applicable, the following:
            </p>
            <ul className="content__body--indent">
              <li>
                The categories of Personal Information about you that we
                collected
              </li>
              <li>
                The categories of sources of Personal Information we collected
              </li>
              <li>
                The categories of third parties with whom we shared your
                Personal Information
              </li>
              <li>
                The business or other purposes for which we collected or sold
                your Personal Information
              </li>
              <li>
                The categories of such Personal Information we sold or disclosed
                for a business purpose, by category for each category of third
                parties to whom the Personal Information was sold or disclosed,
                or we will state that we have not sold or disclosed for a
                business purpose such consumer’s Personal Information.
              </li>
              <li>
                The specific pieces of Personal Information we have collected
                about you.
              </li>
            </ul>
            <p className="content__body">
              The following chart describes the various types of disclosures we
              make to consumers.
            </p>
            <table className="content__table">
              <tr>
                <th>Categories of Personal Information:</th>
                <th>Collected in the last 12 months:</th>
                <th>Categories of sources:</th>
                <th>
                  Business or commercial purposes for collection, use and
                  sharing:
                </th>
                <th>
                  May be disclosed for business purposes to the following
                  categories of third parties:
                </th>
                <th>
                  May be sold to the following categories of third parties:
                </th>
              </tr>
              <tr>
                <td>Personal and online identifiers</td>
                <td>Yes</td>
                <td>All categories listed below.</td>
                <td>All purposes listed above.</td>
                <td>All categories listed below.</td>
                <td>All categories listed below.</td>
              </tr>
              <tr>
                <td>
                  Categories of information described in Section 1798.80(e) of
                  the California Civil Code (such as physical characteristics or
                  description, insurance policy number, financial account number
                  or any other financial information, medical information, or
                  health insurance information)
                </td>
                <td>No</td>
                <td>N/A</td>
                <td>N/A</td>
                <td>N/A</td>
                <td>N/A</td>
              </tr>
              <tr>
                <td>
                  Categories of information described in Section 1798.80(e) of
                  the California Civil Code (such as physical characteristics or
                  description, insurance policy number, financial account number
                  or any other financial information, medical information, or
                  health insurance information)
                </td>
                <td>No</td>
                <td>N/A</td>
                <td>N/A</td>
                <td>N/A</td>
                <td>N/A</td>
              </tr>
              <tr>
                <td>
                  Characteristics of protected classifications under California
                  or federal law (such as race or gender)
                </td>
                <td>No</td>
                <td>N/A</td>
                <td>N/A</td>
                <td>N/A</td>
                <td>N/A</td>
              </tr>
              <tr>
                <td>Commercial or transactions information</td>
                <td>Yes</td>
                <td>All categories listed below.</td>
                <td>All categories listed above.</td>
                <td>All categories listed below.</td>
                <td>All categories listed below.</td>
              </tr>
              <tr>
                <td>
                  Internet or other electronic network activity information
                </td>
                <td>Yes</td>
                <td>All categories listed below.</td>
                <td>All categories listed above.</td>
                <td>All categories listed below.</td>
                <td>All categories listed below.</td>
              </tr>
              <tr>
                <td>Geolocation information</td>
                <td>Yes</td>
                <td>All categories listed below.</td>
                <td>All categories listed above.</td>
                <td>All categories listed below.</td>
                <td>All categories listed below.</td>
              </tr>
              <tr>
                <td>Professional or employment-related information</td>
                <td>Yes</td>
                <td>All categories listed below.</td>
                <td>All categories listed above.</td>
                <td>All categories listed below.</td>
                <td>All categories listed below.</td>
              </tr>
              <tr>
                <td>Education information</td>
                <td>Yes</td>
                <td>All categories listed below.</td>
                <td>All categories listed above.</td>
                <td>All categories listed below.</td>
                <td>All categories listed below.</td>
              </tr>
              <tr>
                <td>
                  Inferences drawn from the above information about your
                  inferred characteristics and preferences
                </td>
                <td>Yes</td>
                <td>All categories listed below.</td>
                <td>All categories listed above.</td>
                <td>All categories listed below.</td>
                <td>All categories listed below.</td>
              </tr>
              <tr>
                <td>
                  Other information about you that is linked to the Personal
                  Information above
                </td>
                <td>Yes</td>
                <td>All categories listed below.</td>
                <td>All categories listed above.</td>
                <td>All categories listed below.</td>
                <td>All categories listed below.</td>
              </tr>
            </table>
            <p className="content__body">
              The following chart describes the various types of disclosures we
              make to consumers.
            </p>
            <ul className="content__body content__body--indent">
              <li>Automotive Companies</li>
              <li>Business to Business Companies</li>
              <li>Consumer Inquiries About our Products/Services</li>
              <li>Consumers</li>
              <li>Electronics Companies</li>
              <li>Financial Services Companies</li>
              <li>Food &amp; Beverage Companies</li>
              <li>Gift Product Companies</li>
              <li>Government and other Public Sources</li>
              <li>Insurance Product Companies</li>
              <li>Lifestyle &amp; Interest Product Companies</li>
              <li>Not for Profit Organizations</li>
              <li>Online &amp; Mobile Websites &amp; Apps</li>
              <li>Travel, Leisure &amp; Entertainment Companies</li>
              <li>Other Product Companies Not Categorized</li>
            </ul>
            <p className="content__body">
              We may disclose the categories of personal information set forth
              above to the following categories of third parties for business
              purposes:
            </p>
            <ul className="content__body content__body--indent">
              <li>Service Providers</li>
              <li>Public or Government Entities</li>
              <li>Advertising Networks</li>
              <li>Internet Service Providers</li>
              <li>Data Analytics Providers</li>
              <li>Operating Systems and Platforms</li>
              <li>Social Networks</li>
            </ul>
            <h3 className="content__heading content__heading--bold">
              Right to Opt Out of Future Data Sale:
            </h3>
            <p className="content__body">
              Certain California residents may have the right to opt out of the
              sale of their Personal Information for marketing purposes by
              following the below instructions.
            </p>
            <p className="content__body">
              We do not sell Personal Information to consumers.
            </p>
            <h3 className="content__heading content__heading--bold">
              Right to Know:
            </h3>
            <p className="content__body">
              Certain California residents have the right to know what Personal
              Information we store about them by following the below
              instructions. Note that the information we provide in a Right to
              Know request represents a sample of the Personal Information we
              store about a consumer. We may store other information that may
              not be related to that consumer or which is overly technical and
              would likely not be reasonably meaningful or helpful to a
              consumer.
            </p>
            <h3 className="content__heading content__heading--bold">
              Right to Know:
            </h3>
            <p className="content__body">
              Certain California residents have the right to know what Personal
              Information we store about them by following the below
              instructions. Note that the information we provide in a Right to
              Know request represents a sample of the Personal Information we
              store about a consumer. We may store other information that may
              not be related to that consumer or which is overly technical and
              would likely not be reasonably meaningful or helpful to a
              consumer.
            </p>
            <h3 className="content__heading content__heading--bold">
              Right to Deletion:
            </h3>
            <p className="content__body">
              Certain California residents have the right to request deletion of
              their Personal Information by following the below instructions. We
              may, to the extent permitted under the California Consumer Privacy
              Act (the “CCPA”) and other applicable laws, retain and continue to
              process Personal Information in accordance with certain
              exceptions.
            </p>
            <h3 className="content__heading content__heading--bold">
              Non-Discrimination:
            </h3>
            <p className="content__body">
              California residents have the right not to receive discriminatory
              treatment for exercising any of the privacy rights conferred by
              the CCPA, except as permitted under the CCPA. If you believe you
              have received discriminatory treatment by us for exercising your
              CCPA privacy rights, please contact us as set forth below.
            </p>
            <h3 className="content__heading content__heading--bold">
              Submitting Requests:
            </h3>
            <p className="content__body">
              Do not send us, directly or indirectly, any sensitive or special
              categories of Personal Information (e.g., social security numbers
              or other national or state identifiers, health information,
              biometric data or genetic characteristics, criminal background
              information, financial account numbers, payment card information,
              and so on) on or through the Website or app or otherwise. We do
              not require and do not request any sensitive or special categories
              of Personal Information in connection with our provision of the
              Services, and it is not needed to respond to your requests.
            </p>
            <p className="content__body">
              To obtain information or assistance regarding a CCPA request,
              please see the below instructions:
            </p>
            <ul>
              <li>
                Please review the balance of this Policy to determine whether it
                provides the information you would like to request.
              </li>
              <li>
                If you have an account or accounts with us, please visit your
                account page in accordance with our{" "}
                <Link to="/terms-of-use/">Terms of Use</Link> to determine
                whether your request may be fulfilled by visiting your account
                page.
              </li>
              <li>
                If you do not have an account with us, and you want to submit a
                CCPA request under the Right of Access, Right to Opt out of
                Future Data Sale, or Right to Deletion, if you believe you have
                received discriminatory treatment for exercising your CCPA
                privacy rights, or if you have any other questions related to
                the CCPA and our processing of your personal information, please
                submit a Privacy Request Submission Form by requesting it
                through{" "}
                <a href="mailto:info@nicesquirrel.com">info@nicesquirrel.com</a>{" "}
                and email or send a copy of the completed form to GS VANLIFE,
                INC., 149 S Barrington Ave, #127, Los Angeles, California 90049.
              </li>
            </ul>
            <p className="content__body content__body--no-indent">
              We may need to verify your identity prior to evaluating your
              request, and if we do not have sufficient information about you to
              verify your identity, for example, if the only information we have
              about you is an email address used to send you marketing
              communications, we may not be able to identify you and will be
              unable to process your request. We will attempt to verify your
              identity by, for example, confirming up to three pieces of
              information you provide in connection with your request match the
              information we possess about you. We may need to request
              additional information to sufficiently identify you. If you submit
              a request on behalf of another consumer as an authorized agent,
              you must submit additional documentation as set forth below.
            </p>
            <h3 className="content__heading content__heading--bold">
              Authorized Agents
            </h3>
            <p className="content__body">
              Authorized agents must submit the following written documentation.
            </p>
            <p className="content__body content__body--no-indent">
              Authorized agents operating as a business must provide:
            </p>
            <ol>
              <li>Organizational documents from the state of formation;</li>
              <li>
                A written authorization document that includes each consumer’s
                name, address, telephone number and valid email address, signed
                and dated by each consumer authorizing the business as the
                authorized agent to act on behalf of each consumer in making the
                request; and
              </li>
              <li>
                A valid email address for each consumer for our direct
                correspondence with each consumer, including as deemed necessary
                or legally required completion of an identity verification
                process.
              </li>
            </ol>
            <p className="content__body content__body--no-indent">
              If you are an individual and acting as an authorized agent on
              behalf of a consumer, please provide:
            </p>
            <ol>
              <li>
                A “power of attorney” signed and dated by the consumer and
                notarized by a notary public naming the agent as the consumer’s
                authorized representative, which includes the consumer’s full
                name, physical address and month/year of birth;
              </li>
              <li>
                If no power of attorney is available, then provide a written
                authorization document that includes the consumer’s name,
                address, telephone number and valid email address, signed by the
                consumer authorizing the agent to act as the authorized agent on
                behalf of the consumer; and
              </li>
              <li>
                A valid email address for each consumer for our direct
                correspondence with each consumer, including as deemed necessary
                or legally required completion of an identity verification
                process.
              </li>
            </ol>
            <h3 className="content__heading content__heading--bold">
              Parent/Guardian of Minor Child*
            </h3>
            <p className="content__body">
              If you are a parent or guardian of a minor child under 18 and
              would like to make a request for a right to know or deletion on
              behalf of the minor child, please provide the following:
            </p>
            <ul className="content__body--indent">
              <li>
                The minor’s verification documents (all are required):
                <ul className="content__body--indent list-style--reset">
                  <li>
                    A certified copy of the minor’s birth certificate or
                    adoption decree
                  </li>
                  <li>
                    Health insurance card (issued by the insurance company)
                    <ul className="content__body--indent list-style--reset">
                      <li>Medicare/Medicaid are accepted</li>
                    </ul>
                  </li>
                  <li>
                    Certified school record or transcript or home school notice
                    of intent form that includes minor’s permanent address
                  </li>
                </ul>
              </li>
              <li>
                Proof of parental/guardianship identity document that matches
                the minor’s permanent residence, which includes one of the
                following:
                <ul className="content__body--indent">
                  <li>Minor’s court order establishing custody</li>
                  <li>Minor’s court order establishing guardianship</li>
                  <li>
                    Court issued parental responsibility documents (for example,
                    court documents on file with presiding court)
                  </li>
                  <li>
                    Current month’s utility bill with parent/guardian’s name and
                    address shown
                  </li>
                </ul>
              </li>
            </ul>
            <p className="content__body content__body--no-indent">
              * For the safety and security of the minor child, no location
              information will be provided in any Personal Information Request
              report issued.
            </p>
            <p className="content__body content__body--no-indent">
              “Shine the Light” Law
            </p>
            <p className="content__body">
              In accordance with the California Shine the Light law, California
              residents may request certain information regarding our disclosure
              (if any) of Personal Information to third parties for their direct
              marketing purposes. Other than to third party recipients as set
              forth in this Policy (“How We May Share Information”), or unless
              you request us to or consent to it, we do not share your Personal
              Information with third parties for their own direct marketing
              purposes. For any questions on these practices, please contact us
              as set forth below.
            </p>
          </li>
          <li>
            <h2 className="content__heading content__heading--medium">
              UPDATES TO THIS POLICY
            </h2>
            <p className="content__body">
              We may update this Policy from time to time. If we believe that
              the changes are material, we will notify you by an appropriate
              means. The most current version of this Policy is located on{" "}
              <Link to="/privacy-policy/">
                https://nicesquirrel.com/privacy-policy/
              </Link>
              .
            </p>
          </li>
          <li>
            <h2 className="content__heading content__heading--medium">
              CONTACTING US
            </h2>
            <p className="content__body">
              If you have any questions about this Policy, please contact us by
              email at{" "}
              <a href="mailto:info@nicesquirrel.com">info@nicesquirrel.com</a>.
            </p>
            <p className="content__body content__body--no-indent">
              December 2020 Update
            </p>
          </li>
        </ol>
      </section>
    </Layout>
  )
}
